@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

$couleur : #E9C86A;

.navbar{
    padding: 0.5rem;
    margin-left: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    a{
        svg{
            fill: white;
    }
    &:nth-child(1)svg{
        width: 40px;
        height: 40px;
    }
    &:nth-child(2)svg{
        width: 60px;
        height: 60px;
    }
}
    h1{
        color:$couleur;
        font-family: 'Cobaissi',sans-serif;
        span{
            color:white;
        }
    }
}

@media(max-width: 472px) {

    .navbar{
        margin-left: 20px;
        justify-content: space-between;
    }
}
