$bluegokhan:#4C797E;
$or:#E9C86A;
$bluedark:#042A30;


.skills {
  width: 100%;
  position: relative;
  padding-inline: 15rem;
  margin-bottom: 200px;
  .skills_title{
    display: flex;
    justify-content: center;
  h2{
    color: white;
    font-family: 'Merriweather',sans-serif;
    margin-bottom: 100px;
    span{
        color:$or;
    }
}
  }
   @media(max-width: 768px) {
    padding-inline: 0;
  }
}

.skills-carousel {
  overflow: hidden;
  width: 100%;
}
.skills-carousel__container {
  display: flex;
  user-select: none;
  margin-left: -10px;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.skill {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 0 0 6rem;

  margin-right: 1rem;
  position: relative;

  gap: 0.5rem;
  padding: 0.8rem 0.6rem;
  width: 5rem;
  border: solid 1px $bluegokhan;
  border-radius: 5px;

  p {
    margin-top: 0.2rem;
    font-size: 0.6rem;
    text-align: center;
    text-transform: uppercase;
    color: white;
    font-family: 'Montserrat',sans-serif;
  }

  img{
    font-size: 3rem;
    text-align: center;
  }
}

.skills-carousel__prev,
.skills-carousel__next {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 3rem;
  height: 2rem;

  color: $or;
  position: absolute;
  left: 10rem;
  top: 75%;
  z-index: 2;
  background-color: transparent;
  font-size: 1rem;
  transform: translateY(-50%);
  border-radius: 50%;
  padding-left: 0.1rem;
  border: none;
  border-color: inherit;

  svg {
    width: 50%;
    height: 50%;
  }

  @media(max-width: 768px) {
    display: none;
  }
}
.skills-carousel__next {
  right: 10rem;
  left: auto;
  padding-right: 0;
}