
$bluegokhan:#4C797E;
$or:#E9C86A;
$bluedark:#042A30;

// 
.project_section{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
    h2{
        color: white;
        font-family: 'Merriweather',sans-serif;
        margin-bottom: 100px;
        span{
            color:$or;
        }
    }
.project-container {
    display: flex;
    margin-bottom: 100px;
    .project-container-img{
        width: 600px;
        height: 400px;
        filter: brightness(0.8);
        object-fit: contain;
    }
    .project-container-content{
    .project-container-content-text{
        width: 500px;
        height: 250px;
        box-shadow: 0 1px 2px rgb(0 0 0 / 10%), 0 2px 4px rgb(0 0 0 / 10%), 0 4px 8px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%), 0 16px 32px rgb(0 0 0 / 10%), 0 32px 64px rgb(0 0 0 / 10%);
        background-color: $bluegokhan;
        padding: 1.5rem;
        border-radius: 5px;
            h2{
              font-size: 1.1rem;
              color: $or;
              margin:0 0 5px;
              font-family: 'Satoshi',sans-serif;
              font-weight: 600;
            }
            p{
              font-size: 0.9rem;
              color: white;
              margin:0 0 10px;
              font-family: 'Satoshi';
              font-weight: 500;
            }
            .project-container-content-info {
                .line{
                    border-bottom: 1px solid rgba(255, 255, 255, 0.493);
                }
            .lang{
                h3{
                    font-size: 1rem;
                    color: white;
                    margin-top: 50px;
                    font-family: 'Satoshi';
                    font-weight: 500;
                    span{
                        font-size: 1rem;
                        color: $or;
                        margin-top: 50px;
                        font-family: 'Satoshi';
                        font-weight: 500;
                    }
                  }
            }
            .link_git{
                display: flex;
                justify-content: end;

            }
        }
    }
}
}
}

@media(max-width: 1200px){
    .project_section{
    .project-container {
        flex-direction: column;
        .project-container-img{
            width: 500px;
            height: 200px;
        }
        .project-container-content{
        .project-container-content-text{
            display: flex;
            flex-direction: column;
            height: auto;
            padding: 1.5rem;
            border-radius: 5px;
        
        }
    }
    }
}
}
@media(max-width: 505px){
    .project_section{
    .project-container{
        padding: 1rem;
        .project-container-img{
            width: 80%;
            height: 80%;
        }
        .project-container-content{
            margin: 0 0 0 9vw;
        .project-container-content-text{
            width: 90%;
            .project-container-content-info{
                .lang{
                    h3{
                        font-size: 0.8rem;
                        span{
                            font-size: 0.9rem;
                        }
                    }
                }
            }
        }
    }
    }
}
}