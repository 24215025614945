@font-face {
    font-family: Satoshi;
    src: url("../../assets/Satoshi-Medium.ttf") format("opentype");
}

@font-face {
    font-family: NewYork;
    src: url("../../assets/NewYork.otf") format("opentype");
}

@font-face {
    font-family: Eireine;
    src: url("../../assets/EireneSans-Regular.otf") format("opentype");
}

@font-face {
    font-family: Megant;
    src: url("../../assets/Megant.ttf") format("opentype");
}

@font-face {
    font-family: Merriweather;
    src: url("../../assets/Merriweather-Regular.ttf") format("opentype");
}

@font-face {
    font-family: Cobaissi;
    src: url("../../assets/COBAISSI.ttf") format("opentype");
}
