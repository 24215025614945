@import './utils/reset';
@import './utils/fonts';


@import './home/aboutme';
@import './home/navbar';
@import './home/skills';
@import './home/projets';
@import './home/apropos';
@import './home/timeline';
@import './home/footer';
@import './home/home';


body{
    background: #042A30;
    
}

::-webkit-scrollbar-thumb {
    background-color: $or;
    border-radius: 90px;
}

::-webkit-scrollbar-track {
    background: #000000;
    border-radius: 90px;
}
::-webkit-scrollbar {
    width: 6px;
}