.footer{
    display: flex;
    justify-content: center;
    ul{
        padding: 1rem;
        display: flex;
        list-style: none;
        gap: 50px;
        font-family: 'Satoshi',sans-serif;
        color: white;
        li{
            display: flex;
            padding: 10px;
            background-color: #477088;
            border-radius: 10px;
            gap:5px;
            &:hover{
                background-color: $or;
            }
            svg{
                width: 20px;
                height: 20px;
            }
            a{
                display: flex;
                gap:5px;
                color: white;
                text-decoration: none;
            }
        }
    }
}

@media (max-width:615px){
    .footer{
        display: flex;
        justify-content: center;
        ul{
            padding: 1rem;
            display: flex;
            gap: 20px;
            li{
                display: flex;
                padding: 5px;
                font-size: 0.8rem;
                gap:5px;
                align-items: center;
                svg{
                    width: 20px;
                    height: 20px;
                }
                a{
                    color: white;
                    text-decoration: none;
                    align-items: center;
                }
            }
        }
    }
}