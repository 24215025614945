.timeline-section{
	background-color: transparent;
	min-height: 100vh;
	padding: 100px 15px;
  margin-bottom: 100px;
  .timeline_title{
    display: flex;
    justify-content: center;
  h2{
    color: white;
    font-family: 'Merriweather',sans-serif;
    margin-bottom: 100px;
    span{
        color:$or;
    }
}
  }
  .timeline-items{
    max-width: 800px;
    margin:auto;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    &::before{
      content: '';
      position: absolute;
      width: 2px;
      height: 100%;
      background-color: $or;
      left: calc(50% - 1px);
    }
    .timeline-item{
      margin-bottom: 40px;
      width: 100%;
      position: relative;
      &:last-child{
        margin-bottom: 0;
      }
      &:nth-child(odd){
        padding-right: calc(50% + 30px);
        text-align: right;
      }
      &:nth-child(even){
          padding-left: calc(50% + 30px);
      }
      .timeline-dot{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        background-color: $or;
        position: absolute;
        left: calc(50% - 20px);
        border-radius: 50%;
        top:10px;
        svg{
          width: 30px;
          height: 30px;
          color:$bluedark;
        }
      }
      .timeline-date{
        font-family: 'Satoshi',sans-serif;
        font-size: 18px;
        color: $or;
        margin:6px 0 15px;
      }
      .timeline-content{
        background-color: $bluegokhan;
        padding: 1.5rem;
        border-radius: 5px;
        h2{
          display: flex;
          align-items: center;
          font-size: 1.1rem;
          color: #ffffff;
          margin:0 0 10px;
          font-family: 'Satoshi',sans-serif;
          font-weight: 600;
        }
        .city{
          display: flex;
          align-items: baseline;
        h3{
          font-size: 1rem;
          color: $or;
          margin:0 0 10px;
          font-family: 'Satoshi';
          font-weight: 500;
        }
        h4{
          font-size: 0.8rem;
          color: white;
          margin:0 0 10px;
          font-family: 'Satoshi';
          font-weight: 500;
        }
        }
      ul{
        list-style: none;
        font-family: 'Satoshi';
        font-size: 1rem;
        li{
          margin-bottom: 5px;
        color: white;
        }
      }
      }
    }
  }
}


@media(max-width: 767px){
  .timeline-section{
    .timeline-items{
	  &::before{
		left: 17px;
  }
  .timeline-item{
  &:nth-child(odd){
    padding-right: 0;
    text-align: left;
  }
  &:nth-child(odd),
  &:nth-child(even){
    padding-left: 37px;
  }
  .timeline-dot{
    left:0;
    height: 35px;
    width: 35px;
  }
}
}
}
}
  