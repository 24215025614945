// $text:rgb(240,192,120);
@import url('https://fonts.googleapis.com/css2?family=Barlow&display=swap');

$bluegokhan:#4C797E;
$or:#E9C86A;
$bluedark:#042A30;



#aboutme {
    margin-top: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    .aboutme-container{
        display: flex;
        background: transparent;
        width: 1300px;
        gap: 50px;
        .aboutme-container-text{
            margin-top:100px ;
        p{
            color:white;
            font-family: 'Merriweather',sans-serif;
            font-size: 2rem;
            padding: 10px;
            font-weight: bold;
            span{
                color:$or;
                font-family: 'Cobaissi',sans-serif;
            }
        }
        h1{
            color:white;
            font-family: 'Merriweather';
        }
        h2{
            color:white;
            font-family: 'Merriweather';
        }
    }

    }
    img{
        background: $bluegokhan;
        border-radius: 250px;
        object-fit: cover;
    }
    .aboutme-container-img {
        margin-left: 10vw;
        width: 40%;
    }
}


@media(max-width: 969px) {

    #aboutme {
        display: flex;
        flex-direction: column;
        .aboutme-container{
            padding: 1rem;
            width: 80%;
            height: 100%;
            flex-direction:column ;
            .aboutme-container-text{
            p{
                font-size: 1.5rem;
            }
        }
        }
        .aboutme-container-img{
           margin-left: 0;
           width: 100%;
        }
    }
}

@media(max-width: 478px) {

    #aboutme {
        .aboutme-container{
            gap:0px;
            .aboutme-container-text{
                h2{
                    font-size: 1.3rem;
                }
        }
        }
    }
}
