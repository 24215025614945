$or:#E9C86A;

.ball {
    width: 50px;
    height: 50px;
    background-color: $or;
    border-radius: 50%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -50;
  }
  