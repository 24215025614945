.apropos{
    display: flex;
    margin-top: 200px;
    justify-content: center;
    .apropos-container-icons{
        display: flex;
        ul{
            flex-direction: column;
            display: flex;
            gap: 2rem;
            list-style: none;
            li{
                h2,h3{
                    color:white;
                    font-family: 'Eireine';
                }
                svg{
                width: 40px;
                height: 40px;
                fill:rgb(255, 255, 255);
                }
            }
        }
    }
    .apropos-container{
        margin-left: 10vw;
        color: white;
        padding: 1rem;
        background: transparent;
        width: 500px;
        height: 400px;
        font-family: 'Satoshi',sans-serif;
       p{
        margin-bottom: 10px;
       }
        b{
            color:$or;
        }
    }
}

@media (max-width:670px) {
    .apropos{
        display: flex;
        margin-top: 150px;
        justify-content: center;
        .apropos-container-icons{
            display: flex;
            padding: 10px;
            ul{
                flex-direction: column;
                display: flex;
                gap: 4rem;
                list-style: none;
                li{
                    h2,h3{
                        color:white;
                        font-family: 'Eireine';
                        line-height: 1rem;
                        font-size: 0.9rem;
                    }
                    svg{
                    width: 30px;
                    height: 30px;
                    }
                }
            }
        }
        .apropos-container{
            margin-left: 20px;
            padding: 1rem;
            width: auto;
            height: auto;
           p{
            margin-bottom: 10px;
            font-size: 0.9rem;
           }
        }
    }
    
}